import React from "react";
import { Link } from "gatsby";

import '../css/text-image.css';

const TextImage = ({ content, section }) => {
  console.log(content);
  return (
    <div className="text-image">
     <div className={`imageWrapper ${content.imagePosition}`}>
      <img className={section} src={content.image.localFile.publicURL} alt={content.image.altText}></img>
      {content.image.caption && (
        <div className="caption" dangerouslySetInnerHTML={{ __html:content.image.caption }} />
      )}
     </div>
    <div className="textWrapper">
      <h3 className="super">{content.superHeadline}</h3>
      <h2 className="headline" dangerouslySetInnerHTML={{ __html: content.headline }} />
      <div className="content" dangerouslySetInnerHTML={{ __html: content.content }} />
      <div className="button-wrapper">
      {content.ctaButtons && content.ctaButtons.length > 0 && (
      <>
          {content.ctaButtons.map((button, index) => (
            <Link key={index} to={button.link.url} target={button.link.target}>
              <button className={button.buttonType}>
                {button.buttonLabel}
              </button>
            </Link>
          ))}
        </>
      )}
      </div>
    </div>
    </div>
  );
}

export default TextImage;
