import React from "react"
import { graphql, Link } from "gatsby"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import '../css/articles.css';

import placeholderImage1 from '../images/default-blog.jpg';

const Articles = ({ title, posts, link, section }) => {
 
  var settings = {
    arrows:false,
    dots: true,
    adaptiveHeight: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };

  let ctaLink = link ? link : '/blog/';

  return (
    <div className="container blogSliderContainer">
      <div className="grid smallGrid align-center">
        <div className="col-sm-8 maincopy">
          <h2>
            {title ? (
              <span dangerouslySetInnerHTML={{ __html: title }} />
            ) : (
              <span>
                Our <span className={section}>Latest</span> Articles
              </span>
            )}
          </h2>

        
        </div>
        <div className="col-sm-4">
          <Link className="mainCTA" to={ctaLink}>View All Articles</Link>
        </div>
      </div>

      <div className="grid smallGrid">
        <div className="col-sm-12">


    <div className="blogSlider">

        <Slider {...settings}>
          { posts &&
          posts.map(post => {
            var backgroundImage = {
              backgroundImage: 'url(' + ((post.featuredImage && post.featuredImage.node) ? post.featuredImage.node.localFile.publicURL : placeholderImage1) + ')'
            }
              
            return (
              <div key={post.id} className="blogBox">
                <a href={post.link}>
                  <div className="blogBoxImage" style={backgroundImage}></div>
                </a>
                <div className="blogBoxBody">
                  <a className="blogBoxTitle" href={post.link}>{post.title}</a>
                  <div dangerouslySetInnerHTML={{ __html: post.excerpt}}></div>
                </div>
                <div className="blogBoxFooter">
                  <div className="grid">
                    <div className="col-sm-7">
                      <p className="blogBoxCat">
                        {post.categories &&
                          post.categories.nodes.map((cat, index) => {
                            return index == 0 ? cat.name : ', '+cat.name;
                          })
                        }
                      </p>
                    </div>
                    <div className="col-sm-5">
                      <a className="blogBoxLink" href={post.link}>Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            )
          })
        }
        </Slider>

    </div>



        </div>
      </div>
    </div>
  )
}


export default Articles