import React from "react"
import { graphql } from "gatsby"

import '../css/cta-image.css';

const CTAImage = ({ leftColumn, rightColumn }) => { 
  
  return (
    <div className="container ctaImageContainer">
      <div className="grid grid-bleed">
        <div className="col-sm-6 ctaImageLeft">
          { leftColumn.image && 
            <img src={leftColumn.image.localFile.publicURL} className="flex-img" alt={leftColumn.image.altText} />
          }
          <div className="ctaImageBox ctaImageBox1">
            <h2>{leftColumn.copy}</h2>
            { leftColumn.cta &&
              <a href={leftColumn.cta.url} target={leftColumn.cta.target}>{leftColumn.cta.title}</a>
            }
          </div>
        </div>
        <div className="col-sm-6 ctaImageLeft">
          { rightColumn.image &&
            <img src={rightColumn.image.localFile.publicURL} className="flex-img" alt={rightColumn.image.altText} />
          }
          <div className="ctaImageBox">
            <h2>{rightColumn.copy}</h2>
            { rightColumn.cta &&
              <a href={rightColumn.cta.url} target={rightColumn.cta.target}>{rightColumn.cta.title}</a>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default CTAImage