import React from "react"
import { graphql } from "gatsby"

import '../css/copyright.css';

const CopyRight = ({ image, title, copy, cta }) => {

  return (
    <div className="container copyRight">
      <div className="grid smallGrid align-center">
        <div className="col-md-6">
          { image && 
            <img src={image.localFile.publicURL} className="flex-img" alt={image.altText} />
          }
        </div>
        <div className="col-md-6 maincopy">
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: copy }}></div>
          { cta &&
            <a className="mainCTA" href={cta.url} target={cta.target}>{cta.title}</a>
          }
        </div>
      </div>
    </div>
  )
}

export default CopyRight