import React from "react"
import { graphql } from "gatsby"

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import '../css/testimonial-slider.css';

const TestimonialSlider = ({ testimonials }) => {
  var settings = {
    arrows:false,
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,

    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
    ]
  };

  return (
    <div className="container testimonialsContainer">
      <div className="grid smallGrid align-center">
        <div className="col-sm-8 maincopy">
          <h2>Testimonials</h2>
          <hr />
        </div>
      </div>
      <div className="testimonialSlider">
        <Slider {...settings}>

          { testimonials &&
            testimonials.map(child => {
              const {firstName, lastName, position, testimonial} = child.cptSingleTestimonial;

              return (
                <div key={child.id}>
                  <div className="testimonialBox maincopy">
                    <p>“{testimonial}”</p>
                    <p className="testAuthor">{firstName} {lastName}</p>
                    { position && 
                      <p className="testPosition">{position}</p>
                    }
                  </div>
                </div>
              )
            })
          }

        </Slider>
      </div>
    </div>
  )
}

export default TestimonialSlider