import React from "react";
import Slider from "react-slick";
import { Link } from "gatsby";

import '../css/image-hero-v2.css';
import '../css/home-hero-v2.css';

const HomeHero = ({ slides }) => {

  var settings = {
    arrows: false,
    dots: false,
    infinite: true,
    autoplaySpeed: 8000,
    slidesToShow: 1,
    autoplay:true,
    slidesToScroll: 1
  };

  return (
    <div className="home-hero-slider">
      <Slider {...settings}>

        {slides &&
          slides.map((slide, index) => {
            // const { firstName, lastName, position, testimonial, signature } = child.cptSingleTestimonial;
            var divStyle = {};
            divStyle.backgroundImage = 'url(' + slide.backgroundImage.localFile.publicURL + ')';
            console.log(divStyle);
            return (
              <div key={index} className="slide-wrapper">
                <div className="container defaultHero imageHero" style={divStyle}>
                  <div className="grid smallGrid">
                  <div className="col-sm-12">
                  <h1 className="home-slide-headline" dangerouslySetInnerHTML={{ __html: slide.headline }} />
                  </div>
                    <div className="col-sm-12">
                      <div className="text-wrapper">        
                        <div className="subheads">
                          {/* Mapping over slide.subheads */}
                          {slide.subheads && slide.subheads.map((subhead, subheadIndex) => (
                            <h3 className={subheadIndex} key={subheadIndex} dangerouslySetInnerHTML={{ __html: subhead.subhead }} />
                          ))}
                        </div>
                        {/* Passing slide.buttons to MyButtons component */}
                        <MyButtons buttons={slide.buttons} />
                      </div>
                      <div className="image-wrapper">
                        {slide.frontImage && 
                          <img src={slide.frontImage.localFile.publicURL} alt={slide.frontImage.altText} className="flex-img" />
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
            
          })}
      </Slider>
    </div>
  );
};

const MyButtons = ({ buttons }) => {
  return (
    <div className="button-wrapper home-hero-buttons">
    {buttons && buttons.length > 0 && (
    <>
        {buttons.map((button, index) => (
          <Link key={index} to={button.link.url} target={button.link.target}>
            <button className={button.buttonType}>
              {button.buttonLabel}
            </button>
          </Link>
        ))}
      </>
    )}
    </div>
  );
};
export { MyButtons }; // Exporting MyButtons as a named export


export default HomeHero;
